<template>
  <head>
    <meta charset="UTF-8" />
    <meta name="description" content="Southborough Community House" />
    <meta name="keywords" content="Southborough, Community, House, Town, " />
    <meta name="author" content="Southborough Community House" />
    <link rel=“canonical” href=“https://southboroughcommunitycenter.org/” />
    <title></title>
  </head>
  <HeaderComponent />
  <div class="relative bg-white py-16">
    <div
      class="absolute inset-x-0 top-0 hidden h-1/2 bg-white lg:block"
      aria-hidden="true"
    ></div>
    <div class="mx-auto max-w-7xl bg-transparent lg:bg-transparent lg:px-8">
      <div class="lg:grid lg:grid-cols-12">
        <div
          class="relative lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:py-16"
        >
          <div
            class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0"
          >
            <div
              class="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1"
            >
              <img
                class="rounded-3xl object-cover object-center shadow-2xl"
                src="https://static.southboroughcommunityhouse.org/community-house/img/house/3_Community_House_out.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          class="relative bg-transparent lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl"
        >
          <div
            class="relative mx-auto max-w-md space-y-6 py-12 px-4 sm:max-w-3xl sm:py-16 sm:px-6 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0"
          >
            <p class="text-lg text-gray-600">
              Welcome to the Southborough Community House home page. We are
              proud to report that we are in our 100th year (Centennial
              celebration 1922 – 2022) supporting the community of Southborough.
              The Southborough Village Society and the Community House is a
              citizen supported non-profit organization. The Southborough
              Community House is not a municipal building and is not supported
              by any State or local Tax funding. We are supported by rental
              income and citizen tax deductible donations as well as the
              good-hearted in-kind assistance of local business and community
              groups.
            </p>
            <p class="text-lg text-gray-600">
              The House itself was built in 1906 for William A. White, a Boston
              lawyer. In 1922 the House was purchased by Charles Francis Choate
              Jr., also a Boston Lawyer and a resident of Southborough. Mr.
              Choate donated the house to the newly formed Southborough Village
              Society as a location to support their community goals and also to
              provide a location to support Veteran groups after the recent end
              of World War I.
            </p>
            <p class="text-lg text-gray-600">
              Since the inception of the Southborough Village Society, the
              Community House has been a focus for community events that improve
              the quality of life of Southborough residents. Historically that
              may have been afternoon social “Teas” on the porch or friendly get
              togethers at the now gone bowling alley on the property. Nowadays
              the Community House remains an attractive site, at the center of
              town, hosting events for the entire town or enabling smaller
              groups to share in the wonderful experiences that our community
              has to offer.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <FooterComponent />
</template>

<script>
import FooterComponent from "@/components/FooterComponent";
import HeaderComponent from "@/components/HeaderComponent";

export default {
  name: "HomeView",
  components: {
    FooterComponent,
    HeaderComponent,
  },
};
</script>
