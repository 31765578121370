import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/santa",
    name: "santa",
    component: () => import("../views/SantaView.vue"),
  },
  {
    path: "/history/house",
    name: "history-house",
    component: () => import("../views/HistoryHouseView.vue"),
  },
  {
    path: "/history/choate",
    name: "history-choate",
    component: () => import("../views/HistoryChoateView.vue"),
  },
  {
    path: "/about/house",
    name: "about-house",
    component: () => import("../views/AboutHouseView.vue"),
  },
  {
    path: "/about/events",
    name: "about-events",
    component: () => import("../views/AboutEventsView.vue"),
  },
  {
    path: "/news/anniversary",
    name: "news-anniversary",
    component: () => import("../views/NewsAnniversaryView.vue"),
  },
  {
    path: "/news/flyer",
    name: "news-flyer",
    component: () => import("../views/NewsFlyerView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
